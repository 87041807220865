@font-face {
font-family: 'biro';
src: url(/_next/static/media/fdec9ba3ffd6b9de-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: 'biro Fallback';src: local("Arial");ascent-override: 129.80%;descent-override: 74.71%;line-gap-override: 2.06%;size-adjust: 82.59%
}.__className_fd2781 {font-family: 'biro', 'biro Fallback'
}

@font-face {
font-family: 'klaklak';
src: url(/_next/static/media/20b76d7802ec7e7d-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: 'klaklak Fallback';src: local("Arial");ascent-override: 73.01%;descent-override: 21.90%;line-gap-override: 0.00%;size-adjust: 136.97%
}.__className_cd83dd {font-family: 'klaklak', 'klaklak Fallback'
}

